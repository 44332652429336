<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="演出名称" name="performName" class="ui-form__item">
						<a-input v-model:value="formState.performName" placeholder="请输入演出名称"></a-input>
					</a-form-item>
					
					<a-form-item label="订单号" name="orderNo" class="ui-form__item">
						<a-input v-model:value="formState.orderNo" placeholder="请输入订单号"></a-input>
					</a-form-item>
					
					<a-form-item label="用户信息" name="userInfo" class="ui-form__item">
						<a-input v-model:value="formState.userInfo" placeholder="请输入用户信息"></a-input>
					</a-form-item>
					
					<a-form-item label="售后发起端" name="refundUserType" class="ui-form__item">
						<a-select v-model:value="formState.refundUserType" style="width: 190px;" placeholder="请选择" allowClear>
							<a-select-option :value="1">用户申请</a-select-option>
							<a-select-option :value="2">管理员主动退单</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="演出时间" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="16">
						<a-button type="primary" style="margin-right: 10px;">导出</a-button>
					</a-col>
					<a-col :span="8" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>

			</a-form>

			<div style="margin-top: 10px;">
				<a-tabs v-model:activeKey="formState.status" type="card" @change="onSearch">
					<a-tab-pane :key="0" tab="全部"></a-tab-pane>
					<a-tab-pane :key="1" tab="待处理"></a-tab-pane>
					<a-tab-pane :key="2" tab="已拒绝"></a-tab-pane>
					<a-tab-pane :key="3" tab="已退款"></a-tab-pane>
				</a-tabs>
				<cTable ref="cTable" style="padding: 0;" :searchData="searchData" :columns="columns" :isPage="true" :isRequest="true" :requestFun="getOrderAfterSaleList" :scroll="{ x: 2500 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'performName'">
							{{ record.perform?.performName || '-' }}
						</template>
						<template v-if="column.key === 'showStartTime'">
							{{ transDateTime(record.perform?.showStartTime) }}
						</template>
						<template v-if="column.key === 'ticketName'">
							{{ record.perform?.ticketName || '-' }}
						</template>
						<template v-if="column.key === 'user'">
							<div>
								<div>用户名称：{{ record.userNickname || '-' }}</div>
								<div>用户手机号：{{ record.userPhone || '-' }}</div>
							</div>
						</template>
						<template v-if="column.key === 'payType'">
							<div>
								<div v-if="record.payMessageType === 'wx_lite'">微信支付</div>
								<div v-else-if="record.payMessageType === 'alipay'">支付宝支付</div>
								<div v-else-if="record.payMessageType === 'card'">一卡通支付</div>
								<div v-else>-</div>
							</div>
						</template>
						<template v-if="column.key === 'orderStatus'">
							{{ transStatus(record.orderStatus) }}
						</template>
						<template v-if="column.key === 'status'">
							{{ transRefundStatus(record.status) }}
						</template>
						<template v-if="column.key === 'sendInfo'">
							<div v-if="record.deliveryType === 3">
								<div>物流公司：{{ record.logisticsCompany }}</div>
								<div>物流单号：{{ record.logisticsNo }}</div>
							</div>
							<div>-</div>
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'refundUserType'">
							{{ ['用户申请', '后台', '柜台'][record.refundUserType - 1] }}
						</template>
						<template v-if="column.key === 'createUserName'">
							<div v-if="record.refundUserType !== 1">{{ record.createUserName || '-' }}</div>
							<div v-else>{{ record.processUserName || '-' }}</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onAuditAfter(record)">
											<a-menu-item>
												同意售后
											</a-menu-item>
										</div>
										<div @click="onReject(record)">
											<a-menu-item>
												拒绝售后
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</cTable>
			</div>

			<a-modal v-model:visible="showRejectSales" destroyOnClose title="拒绝售后" width="500px" @ok="onRejectOk">
				<a-spin :spinning="loading">
					<a-form ref="rejectForm" scrollToFirstError :model="modelRef" name="rejectForm"
						:labelCol="{span: 5}" :wrapperCol="{span: 15 }">
						<a-form-item label="拒绝原因" name="remark">
							<a-textarea v-model:value="modelRef.remark"></a-textarea>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>

	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import cTable from '@/components/cTable/index.vue';
	import { getOrderAfterSaleList, confirmAuditOrderAfterSale, rejectAuditOrderAfterSale } from '@/service/modules/mall.js';
	export default {
		components: {
			Icon,
			cTable
		},
		data() {
			return {
				showRejectSales: false,
				showSendGoods: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				searchData: {},
				modelRef: {
					company: 0,
				},
				formState: {
					saleType: 4
				},
				time: [],
				columns: [{
					title: '演出',
					key: 'performName',
				}, {
					title: '场次时间',
					key: 'showStartTime'
				}, {
					title: '票档',
					key: 'ticketName',
				}, {
					title: '订单号',
					dataIndex: 'orderNo',
					width: 170
				}, {
					title: '用户信息',
					key: 'user',
					width: 220
				}, {
					title: '票数量',
					dataIndex: 'num'
				}, {
					title: '订单价格',
					dataIndex: 'refundTotal',
					width: 120
				}, {
					title: '实际支付',
					dataIndex: 'payment',
					width: 120
				}, {
					title: '支付方式',
					key: 'payType',
					width: 100
				}, {
					title: '优惠券',
					dataIndex: 'couponName'
				}, {
					title: '优惠券优惠金额',
					dataIndex: 'couponDeduction'
				}, {
					title: '一卡通优惠'
				}, {
					title: '订单状态',
					key: 'orderStatus',
					width: 100
				}, {
					title: '售后发起时间',
					key: 'createTime'
				}, {
					title: '退款手续费',
					dataIndex: 'totalServiceFee'
				}, {
					title: '退款金额',
					dataIndex: 'refund'
				}, {
					title: '售后状态',
					key: 'status'
				}, {
					title: '售后发起端',
					key: 'refundUserType'
				}, {
					title: '操作人',
					key: 'createUserName'
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 120
				}],
			}
		},
		mounted() {
			this.onSearch();
		},
		methods: {
			getOrderAfterSaleList: getOrderAfterSaleList,
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.showStartTime = this.time[0].startOf('days').unix();
					this.searchData.showEndTime = this.time[1].endOf('days').unix();
				}
				this.getData(true);
			},
			getData(isReset) {
				if (isReset) {
					// 重置分页查询
					this.$refs.cTable.toQuery();
				} else {
					// 不重置分页查询
					this.$refs.cTable.refQuery();
				}
			},
			onAuditAfter() {
				
			},
			onReject(item) {
				this.modelRef = {
					id: item.id
				}
				this.showRejectSales = true;
			},
			onRejectOk() {
				this.$confirm({
					title: '提示',
					content: '确定审核拒绝吗？',
					onOk: async ()=> {
						this.loading = true;
						let ret = await rejectAuditOrderAfterSale(this.modelRef);
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('审核成功！');
							this.showRejectSales = false;
							this.getData();
						}
					}
				})
			},
			transRefundStatus(status) {
				let value = '';
				switch(status) {
					case 1:
						value = '审核中';
						break;
					case 2:
						value = '待用户发货';
						break;
					case 3:
						value = '用户已发货';
						break;
					case 4:
						value = '审核不通过';
						break;
					case 5:
						value = '用户已撤回';
						break;
					case 6:
						value = '售后完成';
						break;
					case 7:
						value = '改签成功';
						break;
					default:
						value = '-';
						break;
				}
				return value;
			},
			transStatus(status) {
				let value = '';
				switch(status) {
					case 'SYSTEM_CANCEL':
						value = '已关闭'
						break;
					case 'CUSTOMER_CANCEL':
						value = '已关闭'
						break;
					case 'WAIT_BUYER_PAY':
						value = '待付款'
						break;
					case 'WAIT_DIVIDE_SEAT':
						value = '待出票'
						break;
					case 'WAIT_SELLER_SEND_GOODS':
						value = '待发货'
						break;
					case 'WAIT_CHECK':
						value = '待核销'
						break;
					case 'WAIT_BUYER_CONFIRM_GOODS':
						value = '待确认收货'
						break;
					case 'WAIT_COMMENT':
						value = '待评价'
						break;
					case 'TRADE_FINISH':
						value = '已完成'
						break;
					case 'AFTERSALE_FINISH':
						value = '已售后'
						break;
					default:
						value = '-';
						break;
				}
				return value;
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>